/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  MultimodalLiveAPIClientConnection,
  MultimodalLiveClient,
} from "../gem-lib/multimodal-live-client";
import { LiveConfig } from "../multimodal-live-types";
import { AudioStreamer } from "../gem-lib/audio-streamer";
import { audioContext } from "../gem-lib/utils";
import VolMeterWorket from "../gem-lib/worklets/vol-meter";
import { useWebcam } from "./use-webcam";
import { useScreenCapture } from "./use-screen-capture";

export type UseLiveAPIResults = {
  client: MultimodalLiveClient;
  setConfig: (config: LiveConfig) => void;
  config: LiveConfig;
  connected: boolean;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  volume: number;
};

export function useLiveAPI({
  url
}: MultimodalLiveAPIClientConnection): UseLiveAPIResults {
  if (!url) {
    throw new Error('url is required');
  }
  const client = useMemo(
    () => new MultimodalLiveClient({ url }),
    [url],
  );
  const audioStreamerRef = useRef<AudioStreamer | null>(null);
  const { stop: stopWebcam } = useWebcam();
  const { stop: stopScreenCapture } = useScreenCapture();

  const [connected, setConnected] = useState(false);
  const [config, setConfig] = useState<LiveConfig>({
    model: "models/gemini-2.0-flash-exp",
    systemInstruction: {
      parts: [
        { text: "In your first greeting, make a fun, quirky one-sentence remark about the Bit-tensor TAO (pronounced like the word 'tow') network. If users want to get involved, they can go to taostats.io to learn more, join the Bit-tensor Discord server, or visit bittensor.com. This voice assistant is built by OMEGA Labs, which runs Subnet 21 and 24 on Bit-tensor. Talk fast. Also, note that TAO is pronounced like the word 'tow' or Greek symbol 'tau'. Always make a uniquely quirky and different one-sentence remark about the Bit tensor TAO network." }
      ]
    },
    generationConfig: {
      responseModalities: "audio",
      speechConfig: {
        voiceConfig: {
          prebuiltVoiceConfig: {
            voiceName: "Aoede"
          }
        }
      }
    }
  });
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (!audioStreamerRef.current) {
      audioContext({ id: "audio-out" }).then((audioCtx: AudioContext) => {
        audioStreamerRef.current = new AudioStreamer(audioCtx);
        audioStreamerRef.current
          .addWorklet<any>("vumeter-out", VolMeterWorket, (ev: any) => {
            setVolume(ev.data.volume);
          })
          .then(() => {
            // Successfully added worklet
          });
      });
    }
  }, [audioStreamerRef]);

  useEffect(() => {
    const onClose = () => {
      setConnected(false);
    };

    const stopAudioStreamer = () => audioStreamerRef.current?.stop();

    const onAudio = (data: ArrayBuffer) =>
      audioStreamerRef.current?.addPCM16(new Uint8Array(data));

    client
      .on("close", onClose)
      .on("interrupted", stopAudioStreamer)
      .on("audio", onAudio);

    return () => {
      client
        .off("close", onClose)
        .off("interrupted", stopAudioStreamer)
        .off("audio", onAudio);
    };
  }, [client]);

  const connect = useCallback(async () => {
    console.log(config);
    if (!config) {
      throw new Error("config has not been set");
    }
    client.disconnect();
    await client.connect(config);
    client.send({text: "Hi"});
    setConnected(true);
  }, [client, setConnected, config]);

  const disconnect = useCallback(async () => {
    stopWebcam();
    stopScreenCapture();
    client.disconnect();
    setConnected(false);
    audioStreamerRef.current?.stop();
  }, [setConnected, client]);

  return {
    client,
    config,
    setConfig,
    connected,
    connect,
    disconnect,
    volume,
  };
}
