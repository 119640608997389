import { useEffect, useRef } from "react";
import { useLiveAPIContext } from "../../contexts/LiveAPIContext";

const HiddenVideoContext = ({ webcamStream, screenStream }: {
  webcamStream: MediaStream | null;
  screenStream: MediaStream | null;
}) => {
  const { client, connected } = useLiveAPIContext();
  const webcamVideoRef = useRef<HTMLVideoElement>(null);
  const screenVideoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!connected) return;
    
    // Set up video elements
    if (webcamStream && webcamVideoRef.current) {
      webcamVideoRef.current.srcObject = webcamStream;
      webcamVideoRef.current.play().catch(console.error);
    }
    if (screenStream && screenVideoRef.current) {
      screenVideoRef.current.srcObject = screenStream;
      screenVideoRef.current.play().catch(console.error);
    }

    let timeoutId = -1;

    const sendCombinedFrame = () => {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext('2d');
      
      if (!canvas || !ctx) return;

      // Set canvas size based on screen share if available, otherwise webcam
      const mainVideo = screenStream ? screenVideoRef.current : webcamVideoRef.current;
      if (!mainVideo) return;

      canvas.width = mainVideo.videoWidth * 0.25; // Scale down
      canvas.height = mainVideo.videoHeight * 0.25;

      // Draw screen capture if available
      if (screenStream && screenVideoRef.current) {
        ctx.drawImage(screenVideoRef.current, 0, 0, canvas.width, canvas.height);
      }

      // Overlay webcam if both streams are active
      if (webcamStream && webcamVideoRef.current) {
        if (screenStream) {
          // Picture-in-picture when both active
          const webcamWidth = canvas.width * 0.2;
          const webcamHeight = (webcamWidth * 9) / 16;
          const padding = Math.floor(canvas.width * 0.02); // 2% padding
          
          ctx.drawImage(
            webcamVideoRef.current,
            canvas.width - webcamWidth - padding,
            canvas.height - webcamHeight - padding,
            webcamWidth,
            webcamHeight
          );
        } else {
          // Full canvas for webcam only
          ctx.drawImage(webcamVideoRef.current, 0, 0, canvas.width, canvas.height);
        }
      }

      const base64 = canvas.toDataURL("image/jpeg", 0.8);
      const data = base64.slice(base64.indexOf(",") + 1);

      client.sendRealtimeInput([{
        mimeType: "image/jpeg",
        data,
      }]);

      timeoutId = window.setTimeout(sendCombinedFrame, 1000 / 0.5); // 0.5 fps
    };

    sendCombinedFrame();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [webcamStream, screenStream, connected, client]);

  return (
    <div style={{ display: 'none' }}>
      <video ref={webcamVideoRef} autoPlay playsInline muted />
      <video ref={screenVideoRef} autoPlay playsInline muted />
      <canvas ref={canvasRef} />
    </div>
  );
}

export default HiddenVideoContext;