
import { useCallback, useEffect, useRef, useState } from 'react';

import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { WavRenderer } from '../utils/wav_renderer';
import { useWebcam } from '../hooks/use-webcam';

import {
  ArrowRight,
  PencilSimple,
} from '@phosphor-icons/react';

import Spline from '@splinetool/react-spline';
import { useHotkeys } from 'react-hotkeys-hook';
import './ConsolePage.scss';
import Tray from '../components/Tray/Tray';
import { useScreenCapture } from '../hooks/use-screen-capture';
import { useLiveAPIContext } from '../contexts/LiveAPIContext';

// Camera component for displaying the feed
const CameraFeed = () => {
  const { stream: webcamStream } = useWebcam();
  const { isStreaming: isScreenCapturing } = useScreenCapture();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && webcamStream) {
      videoElement.srcObject = webcamStream;
      videoElement.play().catch(console.error);
    }
  }, [webcamStream]);

  return (
    <div className={`absolute bottom-[5rem] right-10 ${isScreenCapturing ? "w-24" :"w-64"} aspect-video bg-black rounded-lg overflow-hidden border border-white/30`}>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className="w-full h-full object-cover camera"
      />
    </div>
  );
};

export const ScreenShare = () => {
  const { stream } = useScreenCapture();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && stream) {
      videoElement.srcObject = stream;
      videoElement.play().catch(console.error);
    }
  }, [stream]);

  return (
    <div className="absolute bottom-[5rem] w-64 aspect-video bg-black rounded-lg overflow-hidden border border-white/30 right-10">
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className="w-full h-full object-cover"
      />
    </div>
  );
};


export function ConsolePageGem() {
  const hasPageLoaded = useRef(false);
  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});

  const { client, connected, connect, disconnect } = useLiveAPIContext();

  const spline = useRef<any>();

  function onLoad(splineApp: any) {
    // save the app in a ref for later use
    spline.current = splineApp;
  }

  const {
    isStreaming: isScreenCapturing,
    stop: stopScreenCapture
  } = useScreenCapture();

  const {
    isStreaming: isWebcamStreaming,
    stop: stopWebcam,
  } = useWebcam();

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#ECECF1',
              10,
              0,
              8
            );
          }
        }

        const result = wavStreamPlayer.analyser
          ? wavStreamPlayer.getFrequencies('voice')
          : { values: new Float32Array([0]) };

        const scaleValue = Math.max(
          1,
          1 + (Math.max(...result.values) / 255) * 50
        );

        if (spline.current) {
          spline.current.setVariable('twinScale', scaleValue);
        }

        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  useEffect(() => {
    const handleClose = (data: any) => {
      if (stopWebcam) stopWebcam();
      if (stopScreenCapture) stopScreenCapture();
    };

    client.on("close", handleClose);

    return () => {
      client.off("close", handleClose);
    };
  }, [stopWebcam, stopScreenCapture]);

  useHotkeys(
    'meta',
    (e) => {
      if (!connected) return;

      if (e.type === 'keydown' && !connected) {
        e.preventDefault(); 
        connect();
      }
      else if (e.type === 'keyup' && connected) {
        e.preventDefault();
        disconnect();
      }
    },
    {
      keydown: true,
      keyup: true,
    },
    [connected, connect, disconnect]
  );

  useEffect(() => {
    if (hasPageLoaded.current) return;

    const handleLoad = async () => {
      hasPageLoaded.current = true;
      await connect();
      if (spline.current) {
        spline.current.emitEvent('mouseDown', 'Twin');
      }
    };

    if (document.readyState === 'complete') {
      handleLoad();
      return () => {
        if (connected) {
          disconnect().catch(error => {
            console.error('Failed to disconnect:', error);
          });
        }
      };
    } else {
      window.addEventListener('load', handleLoad);
      return () => window.removeEventListener('load', handleLoad);
    }
  }, []);

  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">
      <div className="z-50 content-top !py-3 !px-20 mx-auto max-w-5xl w-full">
        <div className="content-title">
          <a href="https://focus.omega.inc?ref=v2v">
            <svg
              width="28"
              height="20"
              viewBox="0 0 28 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8906 1.24879C7.69972 2.34086 5.71522 4.67548 5.71325 8.51561C5.71184 11.7094 7.07787 13.9465 9.77956 15.1738C11.6029 16.0023 11.2519 16.4497 8.77831 16.4497C6.54688 16.4497 5.5625 16.1431 5.5625 15.4481C5.5625 15.2197 5.43594 15.0329 5.28125 15.0329C5.12066 15.0329 5 15.883 5 17.0165V17.9714C5 18.3315 5 18.5115 5.07007 18.649C5.1317 18.77 5.23004 18.8683 5.35101 18.9299C5.48852 19 5.66854 19 6.02857 19H8.69506H11.2516C11.6461 19 11.8433 19 11.9877 18.9208C12.1146 18.8512 12.2142 18.7409 12.2706 18.6077C12.3348 18.456 12.3148 18.2598 12.2749 17.8674L12.1972 17.1029C12.0192 15.3523 11.9202 15.1332 10.915 14.2662C9.58831 13.1222 8.85369 11.3135 8.83091 9.1356C8.77353 3.61514 12.7777 0.408336 16.7098 2.82513C19.0794 4.28161 19.9836 9.32319 18.4423 12.4849C17.9842 13.4249 17.341 14.2483 16.8252 14.5552C16.1569 14.953 15.9682 15.2636 15.9657 15.97C15.964 16.4676 15.8841 17.3531 15.7882 17.9374C15.7408 18.2262 15.7171 18.3705 15.7412 18.4871C15.7855 18.7017 15.936 18.8789 16.1405 18.9574C16.2517 19 16.398 19 16.6906 19H19.3069H21.9714C22.3315 19 22.5115 19 22.649 18.9299C22.77 18.8683 22.8683 18.77 22.9299 18.649C23 18.5115 23 18.3315 23 17.9714V17.0165C23 15.883 22.8793 15.0329 22.7188 15.0329C22.5641 15.0329 22.4375 15.2197 22.4375 15.4481C22.4375 16.1431 21.4531 16.4497 19.2217 16.4497C16.6044 16.4497 16.4663 16.0723 18.6612 14.9168C19.5226 14.4634 20.5323 13.6895 20.905 13.1973C22.327 11.3189 22.804 8.53884 22.1068 6.19402C21.0532 2.65115 15.9634 0.187314 11.8906 1.24879Z"
                fill="white"
                fill-opacity="0.95"
              />
            </svg>
          </a>
          <span className="flex items-center gap-1 bg-black/50 rounded-lg px-3 py-1.5 border border-white/20 font-semibold">OMEGA Any to Any</span>
        </div>
        <div className="flex items-center gap-3 content-api-key">
          <a
            href="https://focus.omega.inc?ref=v2v"
            target="_blank"
            rel="noreferrer"
          >
            <button className='flex items-center gap-1 bg-black/50 rounded-lg px-3 py-1.5 border border-white/20 focus:outline-none hover:opacity-90 transition-opacity'>
              Earn with Focus
              <ArrowRight />
            </button>
          </a>
        </div>
      </div>
      <div className="content-main">
        <div className="fixed -top-[5%] left-0 w-screen h-screen">
          <Spline
            scene="https://prod.spline.design/hx61Lh2cNrNCkER6/scene.splinecode"
            className="relative !w-screen !h-screen"
            onLoad={onLoad}
            style={{ transform: `scale(2.25)` }}
          />
        </div>
        <div className="content-logs h-full w-[400px] pointer-events-none">
          <div className="content-block conversation h-full pb-12">
            <div className="content-block-body mx-auto max-w-7xl w-full !px-20 !pt-5" data-conversation-content>
              {!items.length && `start a conversation...`}
              {items.map((conversationItem, i) => {
                return (
                  <div className="max-w-[400px] pointer-events-auto conversation-item flex flex-col !gap-0" key={conversationItem.id}>
                    <div className={`speaker ${conversationItem.role || ''}`}>
                      <div>
                        {(
                          conversationItem.role ? (conversationItem.role === 'user' ? 'me' : 'Ω') : conversationItem.type
                        ).replaceAll('_', ' ')}
                      </div>
                    </div>
                    <div className="speaker-content">
                      {/* tool response */}
                      {conversationItem.type === 'function_call_output' && (
                        <div>{conversationItem.formatted.output}</div>
                      )}
                      {/* tool call */}
                      {!!conversationItem.formatted.tool && (
                        <div>
                          {conversationItem.formatted.tool.name}(
                          {conversationItem.formatted.tool.arguments})
                        </div>
                      )}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'user' && (
                          <div className='text-white/75 my-2'>
                            {conversationItem.formatted.transcript ||
                              (conversationItem.formatted.audio?.length
                                ? '(awaiting transcript)'
                                : conversationItem.formatted.text ||
                                '(item sent)')}
                          </div>
                        )}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'assistant' && (
                          <div className='text-white/75 my-2'>
                            {conversationItem.formatted.transcript ||
                              conversationItem.formatted.text ||
                              '(truncated)'}
                          </div>
                        )}
                      {/* {conversationItem.formatted.file && (
                        <audio
                          className="pointer-events-auto"
                          src={conversationItem.formatted.file.url}
                          controls
                        />
                      )} */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          
          {isWebcamStreaming && <CameraFeed />}
          {isScreenCapturing && <ScreenShare />}
        </div>
      </div>
      <Tray />
    </div>
  );
}
