import React, { memo, useEffect, useRef, useState } from "react";
import { useLiveAPIContext } from "../../contexts/LiveAPIContext";
import { AudioRecorder } from "../../gem-lib/audio-recorder";
import { Icon, Microphone, MicrophoneSlash, Phone, PhoneDisconnect, Presentation, PresentationChart, VideoCamera, VideoCameraSlash } from "@phosphor-icons/react";
import { Button } from "../button/Button";
import { useScreenCapture } from "../../hooks/use-screen-capture";
import { useWebcam } from '../../hooks/use-webcam';
import HiddenVideoContext from "./HiddenVIdeoContext";

// VideoFeed Component for both webcam and screen share
const VideoFeed = ({ stream, isScreenCapture, className }: {
  stream: MediaStream | null;
  isScreenCapture: boolean;
  className: string;
}
) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && stream) {
      videoElement.srcObject = stream;
      videoElement.play().catch(console.error);
    }

    return () => {
      if (videoElement) {
        videoElement.srcObject = null;
      }
    };
  }, [stream]);

  return (
    <div className={className}>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className="w-full h-full object-cover"
      />
    </div>
  );
};

type MediaStreamButtonProps = {
  isStreaming: boolean;
  OnIcon: Icon;
  OffIcon: Icon;
  onClick: () => void;
  active?: boolean;
};

const MediaStreamButton = memo(
  ({ isStreaming, OnIcon, OffIcon, onClick, active }: MediaStreamButtonProps) => (
    <button 
      className={`flex justify-between items-center gap-2 p-2 rounded-lg ${!active ? "bg-red-500/10" : "bg-black/50"} border border-white/20 cursor-pointer`} 
      onClick={onClick}
    >
      <span className="material-symbols-outlined">
        {active ? <OnIcon size={18} /> : <OffIcon size={18} />}
      </span>
    </button>
  )
);

function Tray() {
  const [inVolume, setInVolume] = useState(0);
  const [audioRecorder] = useState(() => new AudioRecorder());
  const [muted, setMuted] = useState(false);
  const connectButtonRef = useRef<HTMLButtonElement>(null);
  
  const {
    isStreaming: isWebcamStreaming,
    start: startWebcam,
    stop: stopWebcam,
    stream: webcamStream
  } = useWebcam();

  const { client, connected, connect, disconnect } = useLiveAPIContext();
  const {
    isStreaming: isScreenCapturing,
    start: startScreenCapture,
    stop: stopScreenCapture,
    stream: screenStream
  } = useScreenCapture();

  const handleScreenShare = async () => {
    if (isScreenCapturing) {
      stopScreenCapture();
    } else {
      await startScreenCapture();
    }
  };

  const handleCameraShare = async () => {
    if (isWebcamStreaming) {
      stopWebcam();
    } else {
      await startWebcam();
    }
  };

  const baseVideoClasses = "fixed aspect-[11/7] bg-black rounded-lg overflow-hidden";
  
  const webcamClasses = `${baseVideoClasses} z-[100] ${
    isScreenCapturing 
      ? "bottom-[5rem] right-5 w-24"
      : "bottom-[5rem] right-5 w-64"
  }`;
  
  const screenClasses = `${baseVideoClasses} z-[50] ${
    isWebcamStreaming
      ? "bottom-[6rem] right-9 w-64"
      : "bottom-[5rem] right-5 w-64"
  }`;

  useEffect(() => {
    if (!connected && connectButtonRef.current) {
      connectButtonRef.current.focus();
    }
  }, [connected]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--volume",
      `${Math.max(5, Math.min(inVolume * 200, 8))}px`,
    );
  }, [inVolume]);

  useEffect(() => {
    const onData = (base64: string) => {
      client.sendRealtimeInput([
        {
          mimeType: "audio/pcm;rate=16000",
          data: base64,
        },
      ]);
    };
    if (connected && !muted && audioRecorder) {
      audioRecorder.on("data", onData).on("volume", setInVolume).start();
    } else {
      audioRecorder.stop();
    }
    return () => {
      audioRecorder.off("data", onData).off("volume", setInVolume);
    };
  }, [connected, client, muted, audioRecorder]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.key === 'm' || event.key === 'M') && 
          !(event.target instanceof HTMLInputElement) && 
          !(event.target instanceof HTMLTextAreaElement)) {
        setMuted(prev => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  if (!connected) return (
    <div className="flex justify-center items-center w-full">
      <Button className="!px-3 !py-1.5 !rounded-lg button-style-regular !font-semibold !bg-opacity-80 mb-3" onClick={connect}>
        <Phone />Talk with Ω
      </Button>
    </div>
  );

  return (
    <>
      {/* Video Feeds */}
      {webcamStream && (
        <VideoFeed 
          stream={webcamStream} 
          isScreenCapture={false} 
          className={webcamClasses}
        />
      )}
      {screenStream && (
        <VideoFeed 
          stream={screenStream} 
          isScreenCapture={true} 
          className={screenClasses}
        />
      )}
    
      <HiddenVideoContext webcamStream={webcamStream} screenStream={screenStream} />

      {/* Tray Controls */}
      <div className="z-10 w-full px-4 py-3">
        <div className="grid grid-cols-3 items-center">
          <div />

          <div className="flex justify-center items-center gap-5">
            <MediaStreamButton
              isStreaming={isWebcamStreaming}
              OnIcon={VideoCamera}
              OffIcon={VideoCameraSlash}
              onClick={handleCameraShare}
              active={isWebcamStreaming}
            />

            <button
              ref={connectButtonRef}
              className="action-button connect-toggle bg-red-500 rounded-full p-3 focus:outline-none hover:opacity-90 transition-opacity"
              onClick={disconnect}
            >
              <PhoneDisconnect weight="fill" size={24} className="text-white" />
            </button>

            <MediaStreamButton
              isStreaming={isScreenCapturing}
              OnIcon={Presentation}
              OffIcon={PresentationChart}
              onClick={handleScreenShare}
              active={isScreenCapturing}
            />
          </div>

          <div className="flex justify-end">
            <button 
              className={`flex justify-between items-center gap-2 p-2 rounded-lg ${muted ? "bg-red-500/10" : "bg-black/50"} border border-white/20 cursor-pointer`} 
              onClick={() => setMuted(!muted)} 
              title="Toggle mute (M)"
            >
              <span className="w-6 h-6 flex items-center justify-center rounded bg-white/10">
                M
              </span>
              {muted ? (
                <span className="material-symbols-outlined">
                  <MicrophoneSlash size={18} className="text-red-500" />
                </span>
              ) : (
                <span className="material-symbols-outlined">
                  <Microphone size={18} className="text-white" />
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(Tray);