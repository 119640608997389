import { ConsolePage } from './pages/ConsolePage';
import { ConsolePageGem } from './pages/ConsolePageGem';
import './App.scss';
import { LiveAPIProvider } from './contexts/LiveAPIContext';

const RELAY_SERVER_URL: string = process.env.REACT_APP_RELAY_SERVER_URL || '';
console.log(`RELAY_SERVER_URL: ${RELAY_SERVER_URL}`);

function App() {
  return (
    <div data-component="App">
      <LiveAPIProvider url={RELAY_SERVER_URL}>
        <ConsolePageGem />
      </LiveAPIProvider>
    </div>
  );
}

export default App;
